<template>
  <dialogTable
    btnSize="mini"
    btnType="text"
    btnText="插入变量"
    title="插入变量"
    :hasFooter="false"
    :option="option"
    v-bind="$attrs"
  >
    <template #menu>
      <base-button disabled type="text">选择</base-button>
    </template>
  </dialogTable>
</template>

<script>
export default {
  data() {
    return {
      option: {
        topPage: false,
        page: false,
        column: [
          {
            label: '属性名称',
            prop: 'fieldName'
          },
          {
            label: '属性Code',
            prop: 'fieldCode'
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss">

</style>
